<template>
  <div>
    <slot name="title">
      <div class="mb-2"><strong>Добавить теги:</strong></div>
    </slot>
    <template v-if="allowAddNew">
      <v-btn
        small
        class="mr-2"
        :elevation="0"
        :outlined="isSearch ? false : true"
        :disabled="isSearch"
        color="primary"
        @click.prevent="cancelTagCreation"
        >Найти тег</v-btn
      ><v-btn
        small
        :elevation="0"
        :outlined="isSearch ? true : false"
        :disabled="!isSearch"
        color="primary"
        @click.prevent="createTagFromQuery('')"
        >Создать тег</v-btn
      >
    </template>
    <div v-if="isSearch">
      <v-text-field
        v-model="search"
        clearable
        label="Поиск тега"
        :loading="pending"
        autocomplete="new-password"
        prepend-icon="mdi-magnify"
        hint="Начните вводить название тега"
        persistent-hint
      ></v-text-field>
      <div v-if="list.length && !pending">
        <div class="mb-2 mt-2">
          <div v-if="allowAddNew">
            Кликните по тегу, который хотите добавить, если нет подходящего, то
            <a href="#" @click.prevent="createTagFromQuery(search)"
              >создайте новый тег</a
            >:
          </div>
          <div v-else>Кликните по тегу, который хотите добавить</div>
        </div>
        <v-chip
          v-for="item in tagsList"
          :key="item.id"
          :color="item.color"
          class="mr-2 mb-2 list-tag"
          :class="{ 'is-disabled': item.isDisabled }"
          dark
          small
          @click="handleTagClick(item)"
        >
          {{ item.name }}
        </v-chip>
      </div>
      <div v-show="!list.length && searchComplete">
        <slot name="no-results">
          <div class="mt-2">
            <div v-if="allowAddNew">
              Тег &laquo;<strong>{{ search }}</strong
              >&raquo; не найден,
              <a href="#" @click.prevent="createTagFromQuery(search)"
                >Создать тег</a
              >?
              <span class="text-caption">(Откроется форма создания тега)</span>
            </div>
            <div v-else>
              Тег &laquo;<strong>{{ search }}</strong
              >&raquo; не найден
            </div>
          </div>
        </slot>
      </div>
    </div>
    <div v-else>
      <tag-form ref="form" :name="newTagName" @addTag="handleAddTag">
        <template #controls="{ handleSubmit, pending: isPending }">
          <div class="mt-3">
            <v-btn
              class="my-2 mr-2"
              :loading="isPending"
              color="primary"
              @click.prevent="handleSubmit"
              >Добавить тег</v-btn
            >
            <v-btn class="my-2" outlined @click="cancelTagCreation"
              >Отмена</v-btn
            >
          </div>
        </template>
      </tag-form>
    </div>
  </div>
</template>

<script>
import { apiClient } from "@/api";
import TagForm from "@/components/TagForm";
import debounce from "lodash/debounce";
export default {
  name: "AddTag",
  components: {
    TagForm,
  },
  props: {
    selectedTags: {
      type: Array,
      default() {
        // <Array>{id: number, ...any[]}[]
        return [];
      },
    },
    /**
     * @info включение/отключение возможности создавать новые теги
     */
    allowAddNew: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      isSearch: true,
      search: "",
      pending: false,
      limit: 10,
      list: [],
      searchComplete: false,
      newTagName: "",
      // tag: null,
    };
  },
  computed: {
    /**
     * Список id тегов, которые уже выбраны,
     * их нужно запретить добавлять повторно
     */
    selectedIds() {
      return this.selectedTags.map((n) => n.id);
    },
    tagsList() {
      return this.list.map((tag) => {
        return {
          ...tag,
          isDisabled: this.selectedIds.includes(tag.id),
        };
      });
    },
  },
  watch: {
    search: {
      handler() {
        this.searchComplete = false;
        this.debouncedTagSearch();
      },
    },
  },
  created() {
    this.debouncedTagSearch = debounce(this.getTags, 300);
  },
  methods: {
    handleTagClick(tag) {
      const { isDisabled, ...restTag } = tag;
      if (isDisabled) {
        this.$toast("Такой тег уже добавлен", { type: "error" });
        return;
      }
      this.$emit("tagClick", restTag);
    },
    handleAddTag(tag) {
      this.$emit("tagClick", tag);
      this.isSearch = true;
      this.newTagName = "";
    },
    cancelTagCreation() {
      this.isSearch = true;
      this.newTagName = "";
    },
    createTagFromQuery(query) {
      this.newTagName = query;
      this.search = "";
      this.isSearch = false;
    },
    async getTags() {
      if (this.pending) return;
      const { search } = this;
      this.pending = true;
      if (!search) {
        this.pending = false;
        this.list = [];
        return;
      }
      const params = {
        limit: this.limit,
        offset: 0,
      };
      if (search) {
        params.search = search;
      }
      try {
        const { data } = await apiClient({
          method: "GET",
          url: "/tags",
          params,
        });
        this.list = data.results;
      } catch (error) {
        console.log("error", error);
        this._showError(`Не удалось получить список тегов. ${error.message}`);
      }

      this.searchComplete = true;
      this.pending = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.list-tag.is-disabled {
  opacity: 0.2 !important;
}
</style>
