<template>
  <validation-observer ref="form" tag="form" @submit.prevent="handleSubmit">
    <validation-provider
      v-slot="{ errors }"
      :rules="{ required: true, min: 3, max: 500 }"
      vid="message"
      name="message"
    >
      <v-textarea
        v-model="message"
        :error-messages="errors"
        rows="3"
        counter
        auto-grow
        filled
        label="Ваш комментарий"
      ></v-textarea>
    </validation-provider>
    <div v-if="error" class="my-3 error--text">
      Не удалось добавить комментарий, возникла ошибка. {{ error }}
    </div>
    <v-btn color="primary" type="submit" :disabled="!message" :loading="pending"
      >Сохранить</v-btn
    >
  </validation-observer>
</template>

<script>
import { apiClient } from "@/api";
export default {
  name: "CommentForm",
  props: {
    userId: {
      type: Number,
    },
  },
  data() {
    return {
      message: "",
      pending: false,
      error: "",
    };
  },
  methods: {
    reset() {
      this.message = "";
      this.$refs.form.reset();
    },
    async handleSubmit() {
      if (this.pending) return;
      const isValid = await this.$refs.form.validate();
      if (!isValid) return;
      this.error = "";
      this.pending = true;
      try {
        const { data } = await apiClient({
          method: "POST",
          url: `/contacts/${this.userId}/comments`,
          data: {
            message: this.message,
          },
        });
        this.$emit("success", data);
        this.reset();
      } catch (error) {
        this.error = error.message;
        this.$emit("error", error);
      }
      this.pending = false;
    },
  },
};
</script>

<style></style>
