<template>
  <div>
    <div class="d-flex align-start">
      <div class="mb-1 mr-1 align-self-center flex-grow-1">
        <div class="comment-message">{{ comment.message }}</div>
      </div>
      <v-btn
        v-if="deletable"
        color="grey"
        class="mb-1"
        title="Удалить комментарий"
        icon
        @click="handleDelete"
      >
        <v-icon>mdi-delete</v-icon>
      </v-btn>
    </div>
    <div class="d-flex text-caption">
      <div v-if="comment.author" class="mr-2">
        <v-avatar color="#dedede" darken size="18" class="mr-1">
          <img
            v-if="comment.author.avatar"
            class="object-fit-cover"
            :src="comment.author.avatar"
          />
          <v-icon v-else color="white" size="12">mdi-account</v-icon>
        </v-avatar>
        <span
          >{{ comment.author.first_name }} {{ comment.author.last_name }}</span
        >
      </div>
      <div class="grey--text">{{ date }}</div>
    </div>
  </div>
</template>

<script>
import dayjs from "@/plugins/dayjs";
export default {
  name: "CommentCard",
  props: {
    comment: {
      type: Object,
    },
  },

  computed: {
    date() {
      return dayjs(this.comment?.created_at).format("DD.MM.YYYY HH:mm");
    },
    user() {
      return this.$store.getters["user/user"];
    },
    deletable() {
      return this.user.is_superuser || this.comment.author_id === this.user.id;
    },
  },

  methods: {
    async handleDelete() {
      const { comment, user } = this;
      if (comment.author_id === user.id) {
        const confirm = await this.$root.$confirm(
          "Удалить комментарий",
          `Вы действительно хотите удалить этот комментарий?`,
          {
            confirmText: "Да, удалить",
            rejectText: "Нет, отмена",
          }
        );
        if (!confirm) return;
        this.$emit("removeComment", comment.id);
        return;
      }
      if (user.is_superuser) {
        const confirm = await this.$root.$confirm(
          "Удалить комментарий",
          `Этот комментарий оставили не вы. Вы действительно хотите его удалить?`,
          {
            confirmText: "Да, удалить",
            rejectText: "Нет, отмена",
          }
        );

        if (confirm) {
          this.$emit("removeComment", comment.id);
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.comment-message {
  max-width: 600px;
}
</style>
