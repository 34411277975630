<template>
  <v-card :loading="pending">
    <v-card-text class="pa-5 black--text text-body-1">
      <div v-if="pending && !talentUser">
        <v-skeleton-loader type="heading" class="mb-4"></v-skeleton-loader>
        <v-skeleton-loader
          type="paragraph, paragraph"
          class="mb-4"
        ></v-skeleton-loader>
      </div>
      <contact-info :talent-user="talentUser" class="mb-3"></contact-info>

      <!-- Теги  -->
      <info-section
        class="mb-4"
        :total="tags.length"
        :pending="false"
        icon="mdi-tag"
        title="Теги"
        :pages="1"
        :current-page="1"
      >
        <v-chip
          v-for="item in tags"
          :key="item.id"
          :color="item.color"
          class="mr-2 mb-2"
          dark
          small
          :title="`Показать всех пользователей с тегом «${item.name}»`"
          @click.stop="
            $router.push({
              name: 'search',
              query: {
                tags_ids: item.id,
                search: '',
              },
            })
          "
        >
          {{ item.name }}
          <v-icon
            right
            size="18px"
            title="Удалить тег"
            @click.stop="handleRemoveTag(item)"
            >mdi-close</v-icon
          >
        </v-chip>
        <div v-if="!tags.length">Нет тегов</div>
        <template #footer>
          <v-divider></v-divider>
          <v-card-text class="black--text text-body-1 pa-4">
            <tag-search :selected-tags="tags" @tagClick="handleTagClick" />
          </v-card-text>
        </template>
      </info-section>
      <!-- Комментарии  -->
      <info-section
        class="mb-4"
        :total="comments.total"
        :pending="comments.pending"
        icon="mdi-message"
        title="Комментарии"
        :pages="comments.pagesCount"
        :current-page="comments.page"
        @setPage="getComments"
      >
        <comment-card
          v-for="item in comments.list"
          :key="item.id"
          :comment="item"
          class="contact-item"
          @removeComment="handleRemoveComment"
        />
        <div v-if="!comments.total && !comments.pending">Нет комментариев</div>
        <template #footer>
          <v-divider></v-divider>
          <v-card-text class="black--text text-body-1 pa-4">
            <comment-form :user-id="talentId" @success="getComments(1)" />
          </v-card-text>
        </template>
      </info-section>
    </v-card-text>
    <v-divider />

    <v-card-actions class="pa-4">
      <v-btn color="primary" @click="$emit('close')">Закрыть</v-btn>
      <v-btn
        color="primary"
        outlined
        :to="{ name: 'contact', params: { id: talentId } }"
        >Перейти к карточке</v-btn
      >
    </v-card-actions>
  </v-card>
</template>

<script>
import { apiClient } from "@/api";
import ContactInfo from "@/components/contact/ContactInfo";
import CommentCard from "@/components/contact/CommentCard";
import CommentForm from "@/components/contact/CommentForm";
import InfoSection from "@/components/InfoSection";
import TagSearch from "@/components/TagSearch";

import { initialListingModel } from "@/utils";

export default {
  name: "ContactDialog",
  components: {
    ContactInfo,
    CommentCard,
    CommentForm,
    InfoSection,
    TagSearch,
  },
  props: {
    talentId: {
      type: Number,
    },
    isActive: {
      type: Boolean,
    },
  },
  data() {
    return {
      pending: false,
      contact: null,
      tags: [],
      comments: initialListingModel(5),
    };
  },
  computed: {
    talentUser() {
      return this.$store.state.talent.users[this.talentId];
    },
  },
  watch: {
    isActive: {
      immediate: true,
      handler(val) {
        if (val) {
          this.getData();
        } else {
          this.reset();
        }
      },
    },
  },
  methods: {
    reset() {
      this.contact = null;
      this.pending = false;
      this.tags = [];
      this.comments = initialListingModel(5);
    },
    async getUser() {
      await this.$store.dispatch("talent/getUserById", this.talentId);
    },
    async getContact() {
      const { data } = await apiClient({
        method: "GET",
        url: `/contacts/${this.talentId}`,
      });
      this.tags = data.tags;
    },
    async getComments(page = 1) {
      this.comments.pending = true;
      this.comments.error = "";
      this.comments.page = page;
      try {
        const { data } = await apiClient({
          method: "GET",
          url: `/contacts/${this.talentId}/comments`,
          params: {
            limit: this.comments.limit,
            offset: (this.comments.page - 1) * this.comments.limit,
          },
        });
        this.comments.total = data.count;
        this.comments.list = data.results;
        this.comments.pagesCount = Math.ceil(data.count / this.comments.limit);
      } catch (error) {
        this.comments.error = error.message;
      }
      this.comments.pending = false;
    },
    async handleRemoveComment(id) {
      try {
        await apiClient({
          method: "DELETE",
          url: `/contacts/${this.talentId}/comments/${id}`,
        });
        this.getComments();
      } catch (error) {
        this._showError(`Не удалось удалить комментарий. ${error.message}`);
      }
    },
    async getData() {
      this.pending = true;
      await this.getUser();
      await this.getContact();
      this.getComments();
      this.pending = false;
    },

    async handleTagClick(tag) {
      const { data } = await apiClient({
        method: "POST",
        url: `/contacts/${this.talentId}/tags`,
        data: { tags: [tag.id] },
      });
      this.tags.push(tag);
      console.log("data", data);
    },
    async handleRemoveTag(tag) {
      //  Спрашиваем
      const confirm = await this.$root.$confirm(
        "Удалить тег у контакта",
        `Вы действительно хотите удалить тег &laquo;${tag.name}&raquo; у контакта?`,
        {
          confirmText: "Да, удалить",
          rejectText: "Нет, отмена",
        }
      );

      if (!confirm) return;

      if (confirm) {
        try {
          // удаляем
          await apiClient({
            method: "DELETE",
            url: `/contacts/${this.talentId}/tags`,
            data: { tags: [tag.id] },
          });
          const idx = this.tags.findIndex((n) => n.id === tag.id);
          // обновляем локальный стейт, если все оки
          this.$delete(this.tags, idx);
        } catch (error) {
          this._showError("Не удалось удалить тег, возникла ошибка");
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.contact-item {
  border-bottom: 1px solid #c0c0c0;
  padding-bottom: 10px;
  margin-bottom: 10px;

  &:last-child {
    border-bottom: 0;
    margin-bottom: 0;
  }
}
</style>
