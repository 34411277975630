<template>
  <validation-observer ref="form" tag="form" @submit.prevent="handleSubmit">
    <validation-provider
      v-slot="{ errors }"
      name="tag_name"
      :rules="nameValidation"
      vid="tag_name"
    >
      <v-text-field
        v-model.trim="form.name"
        :error-messages="errors"
        label="Название тега"
      ></v-text-field>
    </validation-provider>

    <h4 class="my-2">Цвет тега:</h4>
    <v-color-picker
      v-model="form.color"
      dot-size="30"
      hide-inputs
      hide-mode-switch
      show-swatches
      width="100%"
      hide-canvas
      swatches-max-height="180"
    ></v-color-picker>

    <div v-if="error" class="my-3 error--text">
      Не удалось сохранить тег. {{ error }}
    </div>

    <slot name="controls" :handleSubmit="handleSubmit" :pending="pending">
      <v-btn color="primary" type="submit">Создать тег</v-btn>
    </slot>
  </validation-observer>
</template>

<script>
import { MAX_TAG_NAME, MIN_TAG_NAME, DEFAULT_TAG_COLOR } from "@/constants";
import { apiClient } from "@/api";
/**
 * Создает тег в БД
 */
export default {
  name: "TagForm",
  props: {
    name: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      pending: false,
      error: "",
      form: {
        name: this.name,
        color: DEFAULT_TAG_COLOR,
      },
    };
  },
  computed: {
    nameValidation() {
      return {
        required: true,
        min: MIN_TAG_NAME,
        max: MAX_TAG_NAME,
      };
    },
  },
  methods: {
    reset() {
      this.pending = false;
      this.error = "";
      this.form.name = "";
      this.form.color = DEFAULT_TAG_COLOR;
      this.$refs.form.reset();
    },
    async handleSubmit() {
      const isValid = await this.$refs.form.validate();
      if (!isValid || this.pending) return;
      this.error = "";
      this.pending = true;
      try {
        const { data: tag } = await apiClient({
          method: "POST",
          url: "/tags",
          data: this.form,
        });
        this.$emit("addTag", tag);
        this.reset();
        this.$toast(`Тег ${tag.name} создан`, {});
      } catch (error) {
        // Обрабатываю ошибку про неуникальное название
        if (
          Array.isArray(error.originalData) &&
          error.originalData[0]?.field === "name" &&
          error.originalData[0]?.validation_error === "unique"
        ) {
          this.error = "Тег с таким названием уже существует";
        } else {
          this.error = error.message;
        }
        this.$emit("addTagError", error);
      }
      this.pending = false;
    },
  },
};
</script>

<style></style>
